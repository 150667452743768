import { useLocation, useNavigate} from 'react-router-dom';
import './assets/css/shared.scss';

import 'primeicons/primeicons.css';
import {PrimeReactProvider} from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import {history} from './helpers/history';
import AppRoutes from "./routes/app-routes";
import React, {Suspense, useEffect} from "react";
import  {
    ReactFlowProvider,
} from 'reactflow';
import axios from "axios";
import {SetCampaigns} from "./modules/campaign/redux/campaigns";

export {App};

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();
    const navigate = useNavigate();
    const googleAccount = JSON.parse(localStorage.getItem('google_account'));
    const user = JSON.parse(localStorage.getItem('user'));
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    useEffect(()=>{
        if (!googleAccount && user){

            axios.get(`${baseUrl}/v1/getGoogleAccount`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    localStorage.setItem('google_account', JSON.stringify(response.data.google_account));
                    localStorage.setItem('customer', JSON.stringify(response.data.customer));
                    localStorage.setItem('is_trying_to_connect_with_google', 1);
                    if (response.data.customer){
                        navigate('/')
                    }else {
                        navigate('/choose-customer')
                    }

                })
                .catch((error) => {
                    console.error('Error fetching data:', error);

                })
        }
    },[])

    return (
        <React.StrictMode>
            <ReactFlowProvider>
                <PrimeReactProvider>

                    <div className="app-container bg-light">
                        <Suspense fallback={<></>}>
                            <AppRoutes/>
                        </Suspense>
                    </div>
                </PrimeReactProvider>
            </ReactFlowProvider>
        </React.StrictMode>
    );
}