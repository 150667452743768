import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ series, categories,type,height }) => {
    const chartOptions = {
        chart: {
            height:height ? height : 500,
            type: 'area',
            toolbar:{
                show:false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },

        xaxis: {
            type: 'datetime',
            categories: categories

        },
        tooltip: {
            x: {
                format: 'dd/MM/yy',
            },
        },
    };
    return (
        <div>
            <div id="chart">
                <ReactApexChart options={chartOptions} series={series} type={type} height={height ? height : 350} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ApexChart;
