import React from 'react';

const HorizontalLayout = ({ children }) => {
    return (
        <div>
            <header>Horizontal Layout Header</header>
            <div style={{ display: 'flex' }}>
                <aside>
                    <ul>
                        <li>Menu Item 1</li>
                        <li>Menu Item 2</li>
                        <li>Menu Item 3</li>
                    </ul>
                </aside>
                <main>{children}</main>
            </div>
            <footer>Horizontal Layout Footer</footer>
        </div>
    );
};

export default HorizontalLayout;