import HomeSvgIcon from "../components/icons/homeIcon";
import React from "react";
import CampaignIcon from "../components/icons/CampagnIcon";
import SmartAdviceIcon from "../components/icons/smartAdviceIcon";
import AutomationIcon from "../components/icons/AutomationIcon";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import AudiencesIcon from "../components/icons/AudiencesIcon";
import ContentIcon from "../components/icons/ContentIcon";
import ConfigurationIcon from "../components/icons/ConfigurationIcon";
import campaignNavItems from "../modules/campaign/navigation/items";


const navItems = [
    {
        id: 'home',
        title: 'Home',
        icon: <HomeSvgIcon className="home-svg" />,
        link: '/'
    },
    // {
    //     id: 'campaign',
    //     title: 'Campaign',
    //     icon: <CampaignIcon className="sidebar-svg" />,
    //     subItems: [
    //         {
    //             id: 'campaign_google_ads',
    //             title: 'Google Ads',
    //             link: '/campaign'
    //         },
    //         {
    //             id: 'campaign_meta_ads',
    //             title: 'Meta Ads',
    //             link: '/campaign/meta-ads'
    //         },
    //         {
    //             id: 'campaign_email_marketing',
    //             title: 'E-mail Marketing',
    //             link: '/campaign/email-marketing'
    //         },
    //         {
    //             id: 'campaign_push_notification',
    //             title: 'Push-Notification',
    //             link: '/campaign/push-notification'
    //         },
    //     ]
    // },
    // {
    //     id: 'smart_advices',
    //     title: 'Smart Advices',
    //     icon: <SmartAdviceIcon className="sidebar-svg home-svg" />,
    //     link: '/smart-advices'
    // },
    // {
    //     id: 'automation',
    //     title: 'Create_automation',
    //     icon: <AutomationIcon className="sidebar-svg home-svg" />,
    //     link: '/automation',
    // },
    // {
    //     id: 'analytics',
    //     title: 'Analytics',
    //     icon: <AnalyticsIcon className="sidebar-svg home-svg" />,
    //     link: '/analytics',
    // },
    // {
    //     id: 'audiences',
    //     title: 'Audiences',
    //     icon: <AudiencesIcon className="sidebar-svg" />,
    //     subItems: [
    //         {
    //             id: 'customers',
    //             title: 'Customers',
    //             link: '/audiences/customers'
    //         },
    //         {
    //             id: 'groups',
    //             title: 'Groups',
    //             link: '/audiences/groups'
    //         },
    //     ]
    // },
    // {
    //     id: 'content',
    //     title: 'Content',
    //     icon: <ContentIcon className="sidebar-svg" />,
    //     subItems: [
    //         {
    //             id: 'products',
    //             title: 'Products',
    //             link: '/content/products'
    //         },
    //         {
    //             id: 'media',
    //             title: 'Media',
    //             link: '/content/media'
    //         },
    //         {
    //             id: 'templates',
    //             title: 'Templates',
    //             link: '/content/templates'
    //         },
    //     ]
    // },
    // {
    //     id: 'configuration',
    //     title: 'Configuration',
    //     icon: <ConfigurationIcon className="sidebar-svg config-svg" />,
    //     subItems: [
    //         {
    //             id: 'general',
    //             title: 'General',
    //             link: '/configuration/general'
    //         },
    //         {
    //             id: 'configuration_google_ads',
    //             title: 'Google Ads',
    //             link: '/configuration/google_ads'
    //         },
    //         {
    //             id: 'configuration_meta_ads',
    //             title: 'Meta Ads',
    //             link: '/configuration/meta_ads'
    //         },
    //         {
    //             id: 'configuration_email_marketing',
    //             title: 'E-mail Marketing',
    //             link: '/configuration/email_marketing'
    //         },
    //         {
    //             id: 'configuration_push_notification',
    //             title: 'Push-Notification',
    //             link: '/configuration/push_notification'
    //         },
    //     ]
    // },
]

export default navItems
