import React from 'react';

const HomeSvgIcon = ({ color ,className}) => (
<svg width="40" height="40" viewBox="0 0 40 40" fill="none"  className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9997 30.161C20.3658 30.161 20.6625 29.8643 20.6625 29.4982C20.6625 29.1322 20.3658 28.8354 19.9997 28.8354C19.6337 28.8354 19.3369 29.1322 19.3369 29.4982C19.3369 29.8643 19.6337 30.161 19.9997 30.161Z" fill="black"/>
    <path d="M19.9795 25.5454V9.83899" stroke="black" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39Z" stroke="black" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default HomeSvgIcon;