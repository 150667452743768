import React from 'react';

const MetricsCpc = ({color, className}) => (

    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="14.5455" cy="14.3659" rx="14.5455" ry="14.3659" fill="#F2371E"/>
        <path
            d="M10.7744 16.3053H12.3547C12.3547 17.1586 13.4371 17.8855 14.725 17.8855C16.0129 17.8855 17.0954 17.1586 17.0954 16.3053C17.0954 15.4361 16.2737 15.1201 14.5354 14.7013C12.8603 14.2826 10.7744 13.7611 10.7744 11.5645C10.7744 10.1502 11.9359 8.94922 13.5398 8.54626V6.82379H15.9102V8.54626C17.5142 8.94922 18.6756 10.1502 18.6756 11.5645H17.0954C17.0954 10.7112 16.0129 9.98429 14.725 9.98429C13.4371 9.98429 12.3547 10.7112 12.3547 11.5645C12.3547 12.4337 13.1764 12.7497 14.9147 13.1685C16.5897 13.5872 18.6756 14.1087 18.6756 16.3053C18.6756 17.7196 17.5142 18.9206 15.9102 19.3235V21.046H13.5398V19.3235C11.9359 18.9206 10.7744 17.7196 10.7744 16.3053Z"
            fill="white"/>
        <ellipse cx="25.4546" cy="26.0022" rx="14.5455" ry="14.3659" fill="#F2371E"/>
        <path
            d="M21.6836 27.9416H23.2638C23.2638 28.795 24.3463 29.5219 25.6342 29.5219C26.9221 29.5219 28.0046 28.795 28.0046 27.9416C28.0046 27.0725 27.1829 26.7564 25.4446 26.3377C23.7695 25.9189 21.6836 25.3974 21.6836 23.2009C21.6836 21.7866 22.8451 20.5856 24.449 20.1826V18.4601H26.8194V20.1826C28.4233 20.5856 29.5848 21.7866 29.5848 23.2009H28.0046C28.0046 22.3476 26.9221 21.6206 25.6342 21.6206C24.3463 21.6206 23.2638 22.3476 23.2638 23.2009C23.2638 24.07 24.0856 24.3861 25.8238 24.8048C27.4989 25.2236 29.5848 25.7451 29.5848 27.9416C29.5848 29.3559 28.4233 30.5569 26.8194 30.9599V32.6824H24.449V30.9599C22.8451 30.5569 21.6836 29.3559 21.6836 27.9416Z"
            fill="white"/>
    </svg>


);

export default MetricsCpc;