import React, {useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {Button, Spinner} from "reactstrap";
import axios from "axios";
import {Link} from "react-router-dom";

const AdGroupButtonDiv = ({camp}) => {

    const [adGroups, setAdGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    function GetAdGroup() {
        setLoading(false)
        const baseUrl = `${process.env.REACT_APP_API_URL}`;

        const user = JSON.parse(localStorage.getItem('user'));
        axios.get(`${baseUrl}/v1/get-campaign-ad-group/${camp.id}/${camp.google_type}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                setAdGroups(response.data.data)
                setLoading(true)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(true)
            });
    }

    return (
        <div>
            {
                camp.google_type === 'SHOPPING' || camp.google_type === 'PERFORMANCE_MAX' ?
                    <Button type="button" onClick={GetAdGroup} className={'datatable-action-button'} >
                        {camp.google_type === 'SHOPPING' ? 'See Adgroup' : 'See All Assets'}


                    </Button> : null
            }

            <div className={'ad-group-links-div'}>
                {!loading ?
                    <Spinner/> :
                    camp.google_type === 'SHOPPING' ?
                    adGroups.map(e => {
                        return  <Link key={e.id} to={`/ad-group/${e.campaign_id}/${e.id}`} id={`pw-tooltip-${e.id}`}>
                                <Button color={'info'} className={' mb-1'}>{e.name}</Button>
                            </Link>
                    })  :adGroups.length > 0 ?
                        <Link to={`/asset-group/${camp.id}`} id={`pw-tooltip-${camp.id}`}>
                            <Button color="primary" className={' mb-1'} >View</Button>
                        </Link> : null

                }
            </div>
        </div>
    );
};

export default AdGroupButtonDiv;
