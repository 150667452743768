import { createSlice } from '@reduxjs/toolkit'

export const recommendationsSlice = createSlice({
    name: 'recommendations',
    initialState: {
        value: [],
    },
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = 'metrics'
        },
        decrement: (state) => {
            state.value = ''
        },
        SetRecommendations: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, SetRecommendations } = recommendationsSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(SetRecommendations(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.newCampName.value)`
export const recommendationsValues = (state) => state.recommendations.value

export const allRecommendationsValues = function (state) {
    let recommendations
    recommendations = [];
    if (state.recommendations.value !== []){
        // console.log(state.recommendations.value.length)
        recommendations = state.recommendations.value



        return recommendations
    }else{
        return -1
    }

}

export default recommendationsSlice.reducer
