import React from 'react';

const ChevronsUp = ({color, className}) => (
    <svg width="20" height="26" viewBox="0 0 10 13" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.99839 7.89489L9.35822 12.1373C9.39336 12.1723 9.43507 12.1999 9.48095 12.2186C9.52683 12.2373 9.57596 12.2467 9.6255 12.2463C9.67504 12.2459 9.72401 12.2356 9.76957 12.2162C9.81513 12.1967 9.85638 12.1684 9.89092 12.1329C9.92547 12.0974 9.95264 12.0554 9.97085 12.0093C9.98906 11.9632 9.99795 11.914 9.997 11.8645C9.99606 11.8149 9.9853 11.7661 9.96535 11.7207C9.9454 11.6754 9.91666 11.6344 9.88078 11.6003L5.25967 7.10406C5.18972 7.036 5.09598 6.99792 4.99839 6.99792C4.9008 6.99792 4.80706 7.036 4.73711 7.10406L0.115995 11.6003C0.0801191 11.6344 0.0513754 11.6754 0.0314264 11.7207C0.0114775 11.7661 0.000720978 11.8149 -0.000222206 11.8645C-0.00116539 11.914 0.00772381 11.9632 0.0259323 12.0093C0.0441408 12.0554 0.0713053 12.0974 0.105854 12.1329C0.140404 12.1684 0.181653 12.1967 0.227213 12.2162C0.272774 12.2356 0.321741 12.2459 0.371282 12.2463C0.420823 12.2467 0.469954 12.2373 0.515831 12.2186C0.561708 12.1999 0.603421 12.1723 0.638556 12.1373L4.99839 7.89489Z"
            fill="black" fillOpacity="0.5"/>
        <path
            d="M5.00034 0.896966L9.36018 5.1394C9.39531 5.17433 9.43702 5.20194 9.4829 5.22064C9.52878 5.23934 9.57791 5.24876 9.62745 5.24835C9.67699 5.24794 9.72596 5.23771 9.77152 5.21824C9.81708 5.19878 9.85833 5.17048 9.89288 5.13497C9.92743 5.09947 9.95459 5.05746 9.9728 5.01138C9.99101 4.96531 9.9999 4.91608 9.99896 4.86655C9.99801 4.81701 9.98726 4.76816 9.96731 4.72281C9.94736 4.67746 9.91861 4.63652 9.88274 4.60235L5.26162 0.106131C5.19168 0.0380769 5.09793 0 5.00034 0C4.90275 0 4.80901 0.0380769 4.73906 0.106131L0.117949 4.60235C0.0820723 4.63652 0.0533285 4.67746 0.0333796 4.72281C0.0134306 4.76816 0.0026741 4.81701 0.00173092 4.86655C0.000787735 4.91608 0.00967693 4.96531 0.0278854 5.01138C0.0460939 5.05746 0.0732584 5.09947 0.107807 5.13497C0.142357 5.17048 0.183606 5.19878 0.229166 5.21824C0.274727 5.23771 0.323694 5.24794 0.373235 5.24835C0.422776 5.24876 0.471907 5.23934 0.517784 5.22064C0.563662 5.20194 0.605374 5.17433 0.64051 5.1394L5.00034 0.896966Z"
            fill="black" fillOpacity="0.5"/>
    </svg>


);

export default ChevronsUp;