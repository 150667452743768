import React from 'react';
import { NavLink } from 'react-router-dom';
import {ExpandMoreOutlined} from "@material-ui/icons";
import logo from "../../assets/images/logo.png";

import navItems from '../../navigation/items';
import campaignNavItems from "../../modules/campaign/navigation/items";

const navItemsAll = [
    ...navItems,
    ...campaignNavItems,

];
const Sidebar = () => {

    return (
        <div className="d-flex flex-column flex-shrink-0   sidebar d-sm-none d-md-flex d-xs-none d-none">
                        <a href="/" className="align-items-center mb-3 p-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                            <img src={logo} alt="google" className={'sidebar-logo-image'}/>
                        </a>
            <ul className="nav nav-pills flex-column mb-auto">
                {navItemsAll.map((item) => (
                    <li className="nav-item" key={item.id}>
                        {item.subItems ? (
                            // Render menu item with sub-items
                            <>
                                <div
                                    className="nav-link link-body-emphasis"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#${item.title.replace(/\s/g, '')}SubMenu`}
                                >
                                    {item.icon}
                                    {item.link ?
                                        <NavLink to={item.link} className="nav-link sidebar-nav d-inline-block" style={{paddingLeft:0}}>
                                            <span className="sidebar-title">{item.title}</span>
                                        </NavLink> :
                                        <span className="sidebar-title">{item.title}</span>
                                    }

                                    <ExpandMoreOutlined className="ms-auto expanded-arrow" />
                                </div>
                                <div id={`${item.title.replace(/\s/g, '')}SubMenu`} className="collapse">
                                    <ul className="nav flex-column sub-menu">
                                        {item.subItems.map((subItem) => (
                                            !subItem.hide &&
                                            <li className="nav-item" key={subItem.id}>
                                                <NavLink to={subItem.link} className="nav-link sub-item">
                                                     {subItem.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            // Render regular menu item
                            <NavLink to={item.link} className="nav-link sidebar-nav">
                                {item.icon}
                                <span className="sidebar-title">{item.title}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// const Sidebar = () => {
//     return (
//         <div className="d-flex flex-column flex-shrink-0 p-3 col-2 sidebar">
//             <a href="/" className="align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
//                 <img src={logo} alt="google" className={'sidebar-logo-image'}/>
//             </a>
//             <ul className="nav nav-pills flex-column mb-auto">
//                 <li className="nav-item">
//                     <NavLink to="/" className="nav-link sidebar-nav">
//                         <HomeSvgIcon className={'home-svg'}></HomeSvgIcon>
//                         {/*<img src={homeIcon} alt="google" className={'sidebar-svg home-svg'}/>*/}
//                         Home
//                     </NavLink>
//                 </li>
//
//                 <li className="nav-item">
//                     <div className="nav-link link-body-emphasis" data-bs-toggle="collapse" data-bs-target="#campaignSubMenu">
//                         <CampaignIcon className={'sidebar-svg'}></CampaignIcon>
//                         campaign
//                         <ExpandMoreOutlined className="ms-auto expanded-arrow" />
//                     </div>
//                     <div id="campaignSubMenu" className="collapse">
//                         <ul className="nav flex-column ps-3 sub-menu">
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub1" className="nav-link">- Google Ads</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Meta Ads</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub3" className="nav-link">- E-mail Marketing</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub4" className="nav-link">- Push-Notification</NavLink>
//                             </li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink to="/smart-advice" className="nav-link sidebar-nav">
//                         <SmartAdviceIcon  className={'sidebar-svg home-svg'}></SmartAdviceIcon>
//                         Smart Advices
//                     </NavLink>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink to="/smart-advice" className="nav-link sidebar-nav">
//                         <AutomationIcon  className={'sidebar-svg home-svg'}></AutomationIcon>
//                         Create_automation
//                     </NavLink>
//                 </li>
//                 <li className="nav-item">
//                     <NavLink to="/smart-advice" className="nav-link sidebar-nav">
//                         <AnalyticsIcon  className={'sidebar-svg home-svg'}></AnalyticsIcon>
//                         Analytics
//                     </NavLink>
//                 </li>
//
//                 <li className="nav-item">
//                     <div className="nav-link link-body-emphasis" data-bs-toggle="collapse" data-bs-target="#audiencesSubMenu">
//                         <AudiencesIcon className={'sidebar-svg'}></AudiencesIcon>
//                         Audiences
//                         <ExpandMoreOutlined className="ms-auto expanded-arrow" />
//                     </div>
//                     <div id="audiencesSubMenu" className="collapse">
//                         <ul className="nav flex-column ps-3 sub-menu">
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub1" className="nav-link">- Customers</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Groups</NavLink>
//                             </li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <div className="nav-link link-body-emphasis" data-bs-toggle="collapse" data-bs-target="#contentSubMenu">
//                         <ContentIcon className={'sidebar-svg'}></ContentIcon>
//                         Content
//                         <ExpandMoreOutlined className="ms-auto expanded-arrow" />
//                     </div>
//                     <div id="contentSubMenu" className="collapse">
//                         <ul className="nav flex-column ps-3 sub-menu">
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub1" className="nav-link">- Products</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Media</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Templates</NavLink>
//                             </li>
//                         </ul>
//                     </div>
//                 </li>
//                 <li className="nav-item">
//                     <div className="nav-link link-body-emphasis" data-bs-toggle="collapse" data-bs-target="#configurationSubMenu">
//                         <ConfigurationIcon className={'sidebar-svg config-svg'}></ConfigurationIcon>
//                         Configuration
//                         <ExpandMoreOutlined className="ms-auto expanded-arrow" />
//                     </div>
//                     <div id="configurationSubMenu" className="collapse">
//                         <ul className="nav flex-column ps-3 sub-menu">
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub1" className="nav-link">- General</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Google Ads</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Meta Ads</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- E-mail Marketing</NavLink>
//                             </li>
//                             <li className="nav-item">
//                                 <NavLink to="/campaign/sub2" className="nav-link">- Push-Notification</NavLink>
//                             </li>
//                         </ul>
//                     </div>
//                 </li>
//             </ul>
//         </div>
//     );
// };


export default Sidebar;