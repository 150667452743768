import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Typography} from "@material-ui/core";
import {Link,NavLink} from "react-router-dom";
import InfoSvgIcon from "../../components/icons/InfoIcon";
import CurrencyIcon from "../../components/icons/CurrencyIcon";
import ArrowChart from "../../components/icons/ArrowChart";
import DollarIcon from "../../components/icons/DollarIcon";
import MobileIcon from "../../components/icons/MobileIcon";
import MobileProgressIcon from "../../components/icons/MobileProgressIcon";
import DesktopIcon from "../../components/icons/DesktopIcon";
import DesktopProgressIcon from "../../components/icons/DesktopProgressIcon";
import EyeIcon from "../../components/icons/EyeIcon";
import OptimizationScoreIcon from "../../components/icons/OptimizationScoreIcon";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {Button as IconButtons}  from 'primereact/button';
import FilledCircule from "../../components/icons/FilledCircule";
import ArrowTopIcon from "../../components/icons/ArrowTopIcon";
import Select from 'react-select';
import {faChevronDown, faDollarSign, faFileText, faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MetricsBag from "../../components/icons/MetricsBag";
import MetricsImpressions from "../../components/icons/MetricsImpressions";
import MetricsClick from "../../components/icons/MetricsClick";
import MetricsCpc from "../../components/icons/MetricsCpc";
import MetricsCurrency from "../../components/icons/MetricsCurrency";
import MetricsEye from "../../components/icons/MetricsEye";
import MetricsConversation from "../../components/icons/MetricsConversation";
import MetricsRate from "../../components/icons/MetricsRate";
import MetricsConvValue from "../../components/icons/MetricsConvValue";
import MetricsRoas from "../../components/icons/MetricsRoas";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {Tag} from 'primereact/tag';
import ChevronsUp from "../../components/icons/ChevronsUp";
import TextIcon from "../../components/icons/TextIcon";
import {InputSwitch} from "primereact/inputswitch";
import axios, {all} from "axios";
import ApexChart from "../../modules/campaign/components/chart/ApexChart";
import AdGroupButtonDiv from "../../modules/campaign/components/ad-group-button-div/AdGroupButtonDiv";
import GoogleAccountConnectButton from "../../components/backend_connect_with_google/GoogleAccountConnectButton";
import {Toast} from "primereact/toast";
import PauseCampaignModal from "../../modules/campaign/pages/modal/PauseCampaignModal";
import ApexChartWithoutLines from "../../modules/campaign/components/chart/ApexChartWithoutLines";
import {
    allSystemCustomerMetricsSeries,
    allSystemCustomerMetricsValues, systemCustomerMetricsDailyBudget,
    SetSystemCustomerMetrics
} from "../../modules/campaign/redux/system/systemCustomerMetrics";
import {
    allSystemCampaignsValues,
    SetSystemCampaigns
} from "../../modules/campaign/redux/system/systemCampaigns";
import {ProgressBar} from "primereact/progressbar";
import {VerticalLayoutContext} from "../layout/vertical-layout";
import {useNavigate} from "react-router-dom";
export {SystemHome};

function SystemHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formData } = useContext(VerticalLayoutContext);
    const {user: authUser} = useSelector(x => x.auth);
    const [selectedOption, setSelectedOption] = useState(null);
    const [customerMetrics, setCustomerMetrics] = useState([]);
    const [customerDevices, setCustomerDevices] = useState([]);
    const [metricsChartSeries, setMetricsChartSeries] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
    });
    const [campLoading, setCampLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [rowClick, setRowClick] = useState([]);
    const [adGroupPerformanceScore, setAdGroupsPerformanceScore] = useState(0)
    const systemCustomerMetricsValuesArray = useSelector(allSystemCustomerMetricsValues);
    const systemCustomerMetricsBudget = useSelector(systemCustomerMetricsDailyBudget);
    const systemCustomerMetricsSeriesArray = useSelector(allSystemCustomerMetricsSeries);
    const allSystemCampaigns = useSelector(allSystemCampaignsValues)


    function getSystemData() {
        const user = JSON.parse(localStorage.getItem('user'));
        const baseUrl = `${process.env.REACT_APP_API_URL}`;
        if (allSystemCampaigns.length === 0 && systemCustomerMetricsValuesArray === -1 && systemCustomerMetricsValuesArray === -1 ) {
            setCampLoading(true)
            setLoading(true)

            axios.get(`${baseUrl}/v1/get-site-campaign`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    // setCustomers(response.data.data)
                    dispatch(SetSystemCampaigns(response.data.data.data? response.data.data.data : []))
                    setCampLoading(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setCampLoading(false)
                    // dispatch(SetSystemCampaigns(error.response.data.data))
                });

            axios.get(`${baseUrl}/v1/get-site-info`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setAdGroupsPerformanceScore(response.data.data.data[0].performanceScore)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setAdGroupsPerformanceScore(0)
                });

            axios.get(`${baseUrl}/v1/get-site-metrics`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setCustomerMetrics(response.data.metrics)
                    // // setCustomerDevices(response.data.devices)
                    dispatch(SetSystemCustomerMetrics(response.data))
                    setLoading(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    // dispatch(SetSystemCustomerMetrics(error.response.data))
                    setLoading(false)
                });
        }

    }
    function getSystemDataAfterDelete() {
        const user = JSON.parse(localStorage.getItem('user'));
        const baseUrl = `${process.env.REACT_APP_API_URL}`;

            setCampLoading(true)
            setLoading(true)

            axios.get(`${baseUrl}/v1/get-site-campaign`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    // setCustomers(response.data.data)
                    dispatch(SetSystemCampaigns(response.data.data.data? response.data.data.data : []))
                    setCampLoading(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setCampLoading(false)
                    // dispatch(SetSystemCampaigns(error.response.data.data))
                });

            axios.get(`${baseUrl}/v1/get-site-info`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setAdGroupsPerformanceScore(response.data.data.data[0].performanceScore)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setAdGroupsPerformanceScore(0)
                });

            axios.get(`${baseUrl}/v1/get-site-metrics`,
                {
                    params: {"from": formData.from,"to": formData.to},
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                .then((response) => {
                    setCustomerMetrics(response.data.metrics)
                    // // setCustomerDevices(response.data.devices)
                    dispatch(SetSystemCustomerMetrics(response.data))
                    setLoading(false)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    // dispatch(SetSystemCustomerMetrics(error.response.data))
                    setLoading(false)
                });


    }

    let customMetricsSeries = [];
    if (customerDevices.length  > 0) {
        const spendData = customerMetrics.map(item => item.metrics.spend.toFixed(2));
        const clicksData = customerMetrics.map(item => item.metrics.clicks);



        customMetricsSeries = [
            { name: 'Spend', data: spendData },
            { name: 'Clicks', data: clicksData },
        ];



    }



    const [modalSubmit, setModalSubmit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const [campaignToPause, setCampaignToPause] = useState([]);


    const handleModalSubmit = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Can you send me the report?',
            sticky: true,
            content: (props) => (
                <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                    <ProgressBar color={'#0511F2'} mode="indeterminate" style={{ height: '6px'  }}></ProgressBar>
                </div>
            )
        });
        axios.get(`${baseUrl}/v1/connect-campaign-to-system/${campaignToPause.id}/${campaignToPause.name}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                toast.current.clear();
                dispatch(SetSystemCampaigns(response.data.data))
                setLoading(false)
                toast.current.show({severity: 'success', summary: 'Success', detail: response.data.message});
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false)
            })
        setModalSubmit(true)
        // rowClick[0].status = 'paused';
        // Logic to handle modal submission
        console.log('Modal submitted');

        setIsModalOpen(false);
    };
    const handleModalCancel = () => {
        // Logic to handle modal cancellation
        console.log('Modal cancelled');
        setIsModalOpen(false);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
                <Typography className="m-0 home-data-table-title">Your campaigns</Typography>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.type}</span>
            </div>
        );
    };
    const customEmptyMessage = () => {
        if (!campLoading && allSystemCampaigns.length === 0){
            return (
                <div>
                    No campaigns found.
                </div>

            )
        }else{
            return (
                <Spinner className={'loading-search-location'}/>
            )
        }

    };

    const campaignNameBodyTemplate = (rowData, rowIndex) => {
        const handleSwitchChange = (e) => {
            setCampaignToPause(rowData)
            setIsModalOpen(true);
            const updatedRowClick = [...rowClick];
            updatedRowClick[rowIndex] = e.value;

        };

        return (
            <div className="flex align-items-center gap-2">
                {/*<InputSwitch*/}
                {/*    className="datatable-switch"*/}
                {/*    checked={rowData.is_connected === 1}*/}
                {/*    onChange={handleSwitchChange}*/}
                {/*/>*/}
                <span>{rowData.name}</span>
            </div>
        );
    };
    const handleNavigation = (campId, typeCategories) => {
        navigate('/smart-advices', { state: { campaignId: campId, category: typeCategories } });
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;

        return (
            <div className="flex align-items-center gap-1">
                <button className="circle-button" onClick={() => handleNavigation(rowData.id, 'budget')}>
                    <FontAwesomeIcon className={'datatable-icon'} icon={faDollarSign}/>
                </button>
                <button className="circle-button" onClick={() => handleNavigation(rowData.id, 'bidding')}>
                    <ChevronsUp className={'datatable-icon-chevrons'}></ChevronsUp>
                </button>
                <button className="circle-button" onClick={() => handleNavigation(rowData.id, 'keyword')}>
                    <TextIcon className={'datatable-icon-chevrons'}></TextIcon>
                </button>
                <button className="circle-button" onClick={() => handleNavigation(rowData.id, 'ad')}>
                    <FontAwesomeIcon className={'datatable-icon'} icon={faImage}/>
                </button>
            </div>
        );
    };

    const googleTypeBodyTemplate = (rowData) => {

        return rowData.google_type !== 'SHOPPING' ? <Tag className={'google-type-tag'} value={rowData.google_type}
                                                         style={{background:'rgba(192, 59, 255, 1)',
                                                             borderRadius:'20px',
                                                             fontFamily: 'TTSupermolot-Regular',
                                                             fontSize:'8px',
                                                             padding: '5px'}}/>
            : <Tag className={'google-type-tag'} value={rowData.google_type} style={{background:'rgba(41, 214, 131, 1)',borderRadius:'20px',
                fontFamily: 'TTSupermolot-Regular',
                fontSize:'8px',
                padding: '5px'}}/>
    };

    const spendBodyTemplate = (rowData) => {
        return formatCurrency(rowData.campaignable?.revenue);
    };

    const clicksBodyTemplate = (rowData) => {
        return <span> {rowData?.campaignable?.clicked ?? rowData?.campaignable?.clicks}</span>;
    };

    const handleNavigationToDetails = (id, campaign) => {
        if (campaign.type === 'Google'){
            navigate(`/google-campaign-detail/${id}`, {state: {campaign}});
        }else{
            navigate(`/campaignDetail/${id}`, {state: {campaign}});
        }
    };

    const deleteCampaign = (id, ) => {
        setCampLoading(true)
        axios.post(`${baseUrl}/v1/delete-campaign`, {
            "id" : id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
        })
            .then((response) => {
                setCampLoading(false)
                toast.current.clear()
                toast.current.show({severity: 'success', summary: 'success', detail: response.data.message});

                getSystemDataAfterDelete()
            })
            .catch((error) => {
                setCampLoading(false)
            });
    };
    const actionBodyTemplate = (rowData) => {

        return (
            <div className="flex align-items-center gap-2">
                {/*<Link to={`/campaignDetail/${rowData.name}`} id={`pw-tooltip-${rowData.id}`}>*/}
                <IconButtons className={'datatable-edit-button'} icon="pi pi-eye"
                        onClick={() => handleNavigationToDetails(rowData.token, rowData)} />
                {/*</Link>*/}

                {/*<IconButtons className={'datatable-copy-button'} icon="pi pi-copy"/>*/}
                {/*<IconButtons className={'datatable-calendar-button'} icon="pi pi-calendar"/>*/}
                {
                    rowData.type !== 'Google' &&
                        <>
                            <IconButtons className={'datatable-copy-button'} icon="pi pi-copy"/>
                            <IconButtons className={'datatable-trash-button'} icon="pi pi-trash"
                                         onClick={() => deleteCampaign(rowData.id)}/>
                        </>

                }

            </div>
        );
    };

    const header = renderHeader();

    const options = [
        {value: '1', label: 'Spend vs Click'},
        {value: '2', label: 'Spend vs Impressions'},
        {value: '3', label: 'Impressions vs Clicks'},
        {value: '4', label: 'Spend vs CTR'},
        {value: '5', label: 'Clicks vs CTR'}
    ];

    const handleSelectChange = (selectedOption) => {
        switch (selectedOption.value) {
            case '1': {
                customMetricsSeries = systemCustomerMetricsSeriesArray['allMetricsSeries'].filter(item => item.name === 'Spend' || item.name === 'Clicks');
                setMetricsChartSeries(customMetricsSeries)
                break;
            }
            case '2': {
                customMetricsSeries = systemCustomerMetricsSeriesArray['allMetricsSeries'].filter(item => item.name === 'Spend' || item.name === 'impressions');
                setMetricsChartSeries(customMetricsSeries)
                break;
            }
            case '3': {
                customMetricsSeries = systemCustomerMetricsSeriesArray['allMetricsSeries'].filter(item => item.name === 'impressions' || item.name === 'Clicks');
                setMetricsChartSeries(customMetricsSeries)
                break;
            }
            case '4': {
                customMetricsSeries = systemCustomerMetricsSeriesArray['allMetricsSeries'].filter(item => item.name === 'Spend' || item.name === 'Ctr');
                setMetricsChartSeries(customMetricsSeries)
                break;
            }
            case '5': {
                customMetricsSeries = systemCustomerMetricsSeriesArray['allMetricsSeries'].filter(item => item.name === 'Clicks' || item.name === 'Ctr');
                setMetricsChartSeries(customMetricsSeries)
                break;
            }

        }
        setSelectedOption(selectedOption);
    };




    const [modal, setModal] = useState(false);
    const google_account = JSON.parse(localStorage.getItem('is_trying_to_connect_with_google'));
    const googleCustomer = JSON.parse(localStorage.getItem('customer'));
    const googleUser = JSON.parse(localStorage.getItem('google_account'));
    useEffect(() => {
        getSystemData()
    }, []);
    // useEffect(() => {
    //     if (google_account === null || google_account === 0) {
    //
    //     }else {
    //         setModal(false);
    //         if (googleCustomer === null && googleUser !== null) {
    //             window.location.href = '/choose-customer'
    //         }else {
    //             getSystemData()
    //         }
    //     }
    // }, [google_account]);



    const toggle = () => setModal(!modal);
    const toast = useRef(null);



    return (
        <div>
            <Toast ref={toast} />
            <PauseCampaignModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
                onCancel={handleModalCancel}
                campaignId={campaignToPause}
            />
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Connect To Google</ModalHeader>
                <ModalBody>
                    <GoogleAccountConnectButton  />
                </ModalBody>
                <ModalFooter>
                    {/*<Button color="primary" onClick={toggle}>*/}
                    {/*    Do Something*/}
                    {/*</Button>{' '}*/}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="container-fluid setup-account ">
                <Typography className={'setup-account-typography'}> You should continue the shop connect process
                    <NavLink to="/account-setup">
                        <span className={'setup-account-link'}>Setup Account</span>
                    </NavLink>
                </Typography>
            </div>
            {/*{ googleUser === null ?*/}
            {/*    <div className="container-fluid setup-account ">*/}
            {/*        <Typography className={'setup-account-typography'}>*/}
            {/*            You should Connect with Google Account*/}
            {/*            <button onClick={() => setModal(true)} className={'connect-button-home'}>*/}
            {/*                <span className={'setup-account-link'}>Connect</span>*/}
            {/*            </button>*/}
            {/*        </Typography>*/}
            {/*    </div> : <></>*/}
            {/*}*/}
            <div className="container-fluid home-container">
                <div className="row-chart">
                    <div className="col-1-chart">
                        <div className={'row'}>
                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <CurrencyIcon className={'currency-svg'}></CurrencyIcon>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['amountSpend'] : (!loading ? 0 : <Spinner></Spinner>)}
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Amount spent
                                        </Typography>
                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#98ecc3']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['spend'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>
                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <ArrowChart className={'arrow-chart-svg'}></ArrowChart>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['clicks'] : (!loading ? 0 : <Spinner></Spinner>)}
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Clicks
                                        </Typography>
                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#857fb7']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['clicks'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>
                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <DollarIcon className={'arrow-chart-svg'}></DollarIcon>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['average_cpc'] : (!loading ? 0 : <Spinner></Spinner>)}
                                            <span className={'chart-card-value-subtitle'}>(EUR)</span>
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Cost per click(CPC)
                                        </Typography>
                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#ddb785']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['cpc'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>

                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <EyeIcon className={'arrow-chart-svg'}></EyeIcon>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['impressions'] : (!loading ? 0 : <Spinner></Spinner>)}
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Impression
                                        </Typography>
                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#f0d064']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['impressions'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>
                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <DollarIcon className={'arrow-chart-svg'}></DollarIcon>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['average_cpm'] : (!loading ? 0 : <Spinner></Spinner>)}
                                            <span className={'chart-card-value-subtitle'}>(EUR)</span>
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Cost per mile(CPM)
                                        </Typography>
                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#5a934e']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['cpm'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>
                            <div className={'col-md-4  col-sm-6 col-xs-12 mobile-card-row'}>
                                <div className={'card statistic-card'}>
                                    <div className={'card-body'}>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <Typography className={'statistic-card-title'}>
                                            %
                                        </Typography>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['ctr'] : (!loading ? 0 : <Spinner></Spinner>)}
                                            <span className={'chart-card-value-subtitle'}>%</span>
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Score(CTR)
                                        </Typography>

                                    </div>
                                    <ApexChartWithoutLines height={150} color={['#ddb6d6']} series={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['ctr'] : []
                                    }  categories={
                                        systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['date'] : []
                                    }/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2-chart">
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'card wide-statistic-card'}>
                                    <div className={'card-body'}>
                                        <MobileIcon className={'mobile-icon'}></MobileIcon>
                                        <Typography className={'chart-card-views-title'}>
                                            Mobile
                                        </Typography>
                                        <Typography className={'chart-card-views-subtitle'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['mobilePercentage'] : (!loading ? 0 : <Spinner></Spinner>)}%
                                        </Typography>
                                        <MobileProgressIcon className={'mobile-progress-bar'} progress={
                                            systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['mobilePercentage'] * 5 : 0
                                        }></MobileProgressIcon>
                                        <DesktopIcon className={'desktop-icon'}></DesktopIcon>
                                        <Typography className={'chart-card-views-title'}>
                                            Desktop
                                        </Typography>
                                        <Typography className={'chart-card-views-subtitle'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['desktopPercentage'] : (!loading ? 0 : <Spinner></Spinner>)}
                                            %
                                        </Typography>
                                        <DesktopProgressIcon className={'desktop-progress-bar'} progress={
                                            systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['desktopPercentage'] * 5 : 0
                                        }></DesktopProgressIcon>

                                        <Typography className={'chart-card-views-title'}>
                                            Devices
                                        </Typography>
                                        <ApexChartWithoutLines height={120} color={['#98ecc3','#857fb7']} series={
                                            systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['devices'] : []
                                        }  categories={
                                            systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['devicesDate'] : []
                                        }/>
                                        {/*<ViewChart className={'chart-svg-home'}></ViewChart>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'card wide-statistic-card'}>
                                    <div className={'card-body'}>
                                        <div className={'row'}>
                                            <div className={'col-md-1'}>

                                            </div>
                                        </div>
                                        <InfoSvgIcon className={'info-svg'}></InfoSvgIcon>
                                        <Typography className={'statistic-card-title'}>
                                            %
                                        </Typography>
                                        <Typography className={'chart-card-value'}>
                                            {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['optimization_score'] : (!loading ? 0 : <Spinner></Spinner>)}
                                            <span className={'chart-card-value-subtitle'}>%</span>
                                        </Typography>
                                        <Typography className={'chart-card-title'}>
                                            Optimization score
                                        </Typography>

                                        <OptimizationScoreIcon className={'optimization-progress-bar'} percentage={
                                            systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['optimization_score'] * 5 : 0
                                        }></OptimizationScoreIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className={'home-status'}>*/}
                {/*    <div className={'optimization-status'}>*/}
                {/*        <Typography className={'optimization-status-typography'}>*/}
                {/*            Optimization status*/}
                {/*        </Typography>*/}
                {/*        <div className={'row buttons-row'}>*/}
                {/*            <div className="col-xl-3 col-lg-3 col-md-6 status-button-div">*/}
                {/*                <Button className={'optimization-status-button'}>*/}
                {/*                    <FilledCircule className={'circle-button-before-title'}></FilledCircule>*/}
                {/*                    <Typography className={'optimization-status-button-title'}>*/}
                {/*                        Budget*/}
                {/*                    </Typography>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*            <div className="col-xl-3 col-lg-3 col-md-6 status-button-div">*/}
                {/*                <Button className={'optimization-status-button'}>*/}
                {/*                    <FilledCircule className={'circle-button-before-title'}></FilledCircule>*/}
                {/*                    <Typography className={'optimization-status-button-title'}>*/}
                {/*                        Bidding*/}
                {/*                    </Typography>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*            <div className="col-xl-3 col-lg-3 col-md-6 status-button-div">*/}
                {/*                <Button className={'optimization-status-button'}>*/}
                {/*                    <FilledCircule className={'circle-button-before-title'}></FilledCircule>*/}
                {/*                    <Typography className={'optimization-status-button-title'}>*/}
                {/*                        Keyword*/}
                {/*                    </Typography>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*            <div className="col-xl-3 col-lg-3 col-md-6 status-button-div">*/}
                {/*                <Button className={'optimization-status-button'}>*/}
                {/*                    <FilledCircule className={'circle-button-before-title'}></FilledCircule>*/}
                {/*                    <Typography className={'optimization-status-button-title'}>*/}
                {/*                        Ads*/}
                {/*                    </Typography>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2"></div>*/}
                {/*            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">*/}
                {/*                <Button className={'optimization-status-button-start'}>*/}
                {/*                    <Typography className={'optimization-status-button-start-title'}>*/}
                {/*                        Start or manage optimize*/}
                {/*                    </Typography>*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2"></div>*/}

                {/*        </div>*/}

                {/*    </div>*/}
                {/*    <div className="vertical-line-home-status">*/}
                {/*        <div className="vr vr-optimization"></div>*/}
                {/*    </div>*/}
                {/*    <div className={'optimization-status'}>*/}
                {/*        <Typography className={'optimization-status-typography'}>*/}
                {/*            Ad group performance score*/}
                {/*        </Typography>*/}
                {/*        <div className={'row buttons-row'}>*/}
                {/*            <Button className={'performance-min-button'}>*/}
                {/*                <Typography className={'performance-min-button-title'}>*/}
                {/*                    Min*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*            <Button className={'performance-low-button'}>*/}
                {/*                <Typography className={'performance-low-button-title'}>*/}
                {/*                    Low*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*            <Button className={'performance-mid-button'}>*/}
                {/*                <Typography className={'performance-mid-button-title'}>*/}
                {/*                    Mid*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*            <Button className={'performance-high-button'}>*/}
                {/*                <Typography className={'performance-high-button-title'}>*/}
                {/*                    High*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*            <Button className={'performance-max-button'}>*/}
                {/*                <Typography className={'performance-max-button-title'}>*/}
                {/*                    Max*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*            <div id="bar-status">*/}
                {/*                <div className={`text-center d-flex flex-column align-items-center */}
                {/*                ${adGroupPerformanceScore < 20  ? 'min' : adGroupPerformanceScore < 40  ? 'low' :  adGroupPerformanceScore < 60  ? 'mid' : adGroupPerformanceScore < 80  ? 'high' : 'max'}`}>*/}
                {/*                    <ArrowTopIcon className={'arrow-top'}></ArrowTopIcon>*/}
                {/*                    <Typography className={'performance-percentage-value d-inline-block'}>{adGroupPerformanceScore}%</Typography>*/}
                {/*                </div>*/}
                {/*            </div>*/}


                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'main-metrics'}>
                    <div className={'row'}>
                        <div className={'col-md-6 metrics-half'}>
                            <Typography className={'main-metrics-title'}>
                                Main metrics
                            </Typography>
                        </div>
                        <div className={'col-md-6 custom-select metrics-half'}>
                            <Select
                                id="mySelect"
                                value={selectedOption}
                                onChange={handleSelectChange}
                                options={options}
                                placeholder="Spend vs clicks"
                                classNamePrefix="custom-select"
                                className={' main-metrics-select form-control'}
                                components={{
                                    DropdownIndicator: () => <FontAwesomeIcon className={'select-icon'}
                                                                              icon={faChevronDown}/>
                                }}
                            />
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsBag className={'metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Daily budget
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsBudget > -1 ? systemCustomerMetricsBudget.toFixed(2): (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                <span>EUR</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsImpressions className={'mt-2 mb-2 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Impressions
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['impressions'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsClick className={'mt-2 mb-1 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Clicks
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['clicks'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsCpc className={'mt-2 mb-1 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                CPC
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['average_cpc'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                <span>EUR</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsCurrency className={'mt-2 mb-1 metrics-card-svg'} color={"#FFA41B"}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                spend
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['amountSpend'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                <span>EUR</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <Link to={'/customer-recommendations'} style={{textDecoration:'none'}}>
                                        <div className="card metrics-card">
                                            <div className="card-body metrics-card-body">
                                                <MetricsEye className={'mt-2 mb-2 metrics-card-svg'}/>
                                                <Typography className={'metrics-card-body-typography'}>
                                                    Optimization score
                                                </Typography>
                                                <Typography className={'metrics-card-body-typography-number'}>
                                                    {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['optimization_score'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                    <span>%</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </Link>

                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsConversation className={'mt-2 mb-1 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Total conversions
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['conversions'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsRate className={'mt-2 mb-2 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Conversions rate
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['conversions_rate'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                <span>%</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsCurrency className={'mt-2 mb-2 metrics-card-svg'} color={'black'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Cost per conversions
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['cost_per_conversions'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                                <span>EUR</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsConvValue className={'mt-2 mb-1 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                Conversions value
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['conversions_value'] : (!loading ? 0 : <Spinner size={'sm'}></Spinner>)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 metrics-card-parent">
                                    <div className="card metrics-card">
                                        <div className="card-body metrics-card-body">
                                            <MetricsRoas className={'mt-2 mb-2 metrics-card-svg'}/>
                                            <Typography className={'metrics-card-body-typography'}>
                                                ROAS
                                            </Typography>
                                            <Typography className={'metrics-card-body-typography-number'}>
                                                {systemCustomerMetricsValuesArray > -1 ? systemCustomerMetricsValuesArray['roas'] : (!loading ? 0 : <Spinner  size={'sm'}></Spinner>)}
                                                <span>EUR</span>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {metricsChartSeries.length > 0 ?
                                <ApexChart series={metricsChartSeries} categories={
                                    systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['devicesDate'] : []
                                } type={'area'} /> :
                                <ApexChart series={customMetricsSeries} categories={
                                    systemCustomerMetricsSeriesArray > -1 ?   systemCustomerMetricsSeriesArray['devicesDate'] : []
                                } type={'area'} />
                            }

                        </div>
                    </div>
                </div>
                <div className={'home-datatable-div '}>
                    <DataTable value={allSystemCampaigns.length === 0 ? [] : allSystemCampaigns  }  header={header} rows={10} style={{borderCollapse: 'separate',borderSpacing: '10px'}}
                               paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               rowsPerPageOptions={[10, 20, 30,50,100]} dataKey="id" selectionMode="checkbox" selection={selectedCustomers} onSelectionChange={(e) => setSelectedCustomers(e.value)}
                               filters={filters} filterDisplay="menu" globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']}
                               emptyMessage={customEmptyMessage} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <Column selectionMode="multiple" headerStyle={{ minWidth: '5px',width: '5px',padding:'0px' }}></Column>
                        <Column field="name" header="Campaign Name" sortable  style={{ minWidth: '7rem',width:'7rem',borderRight:'1px solid rgba(0, 0, 0, 0.1)',borderBottom:'1px solid rgba(0, 0, 0, 0.1)' }} body={(rowData, rowIndex) => campaignNameBodyTemplate(rowData, rowIndex.rowIndex)}/>
                        <Column field="type" header="Type"  style={{ minWidth: '3rem',width:'3rem' }} body={typeBodyTemplate} />
                        <Column header="Optimization"  filterField="representative"
                                style={{ minWidth: '3rem',width:'3rem' }} body={representativeBodyTemplate}  />
                        {/*<Column field="date" header="Google Type" sortable filterField="date" style={{ minWidth: '5rem',width:'5rem' }} body={googleTypeBodyTemplate}  />*/}
                        <Column field="revenue" header="Revenue" sortable  dataType="numeric" style={{ minWidth: '3rem',width:'3rem' }} body={spendBodyTemplate} />
                        <Column field="clicks" header="Clicks"  filterMenuStyle={{ width: '3rem' }} style={{ minWidth: '3rem',width:'3rem' }} body={clicksBodyTemplate} />
                        <Column field="action" header="Actions" headerStyle={{minWidth:'5rem', width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </div>


        </div>
    );
}