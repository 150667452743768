import React from 'react';

const TextIcon = ({color, className}) => (
    <svg width="20" height="26" viewBox="0 0 12 16" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0.666667C0 0.489856 0.070238 0.320287 0.195262 0.195262C0.320287 0.070238 0.489856 0 0.666667 0H11.3333C11.5101 0 11.6797 0.070238 11.8047 0.195262C11.9298 0.320287 12 0.489856 12 0.666667V3.33333C12 3.51014 11.9298 3.67971 11.8047 3.80474C11.6797 3.92976 11.5101 4 11.3333 4C11.1565 4 10.987 3.92976 10.8619 3.80474C10.7369 3.67971 10.6667 3.51014 10.6667 3.33333V1.33333H6.66667V14.6667H8C8.17681 14.6667 8.34638 14.7369 8.47141 14.8619C8.59643 14.987 8.66667 15.1565 8.66667 15.3333C8.66667 15.5101 8.59643 15.6797 8.47141 15.8047C8.34638 15.9298 8.17681 16 8 16H4C3.82319 16 3.65362 15.9298 3.5286 15.8047C3.40357 15.6797 3.33333 15.5101 3.33333 15.3333C3.33333 15.1565 3.40357 14.987 3.5286 14.8619C3.65362 14.7369 3.82319 14.6667 4 14.6667H5.33333V1.33333H1.33333V3.33333C1.33333 3.51014 1.2631 3.67971 1.13807 3.80474C1.01305 3.92976 0.843478 4 0.666667 4C0.489856 4 0.320287 3.92976 0.195262 3.80474C0.070238 3.67971 0 3.51014 0 3.33333V0.666667Z"
            fill="black" fillOpacity="0.5"/>
    </svg>


);

export default TextIcon;