import React from 'react';

const DesktopProgressIcon = ({className,progress}) => (

    <svg width="500" height="17" viewBox="0 0 500 17" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <rect y="16.9918" width="16.9918" height="500" rx="8.49591" transform="rotate(-90 0 16.9918)" fill="#D1D1D1"
              fillOpacity="0.3"/>
        <rect y="17" width="17" height={progress} rx="8.5" transform="rotate(-90 0 17)" fill="black"/>
    </svg>


);

export default DesktopProgressIcon;