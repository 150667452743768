import React, {useEffect, useCallback, useState} from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import googleAccount, {
    googleAccountValue,
    googleAccountValues,
    SetGoogleAccount
} from "../../modules/campaign/redux/googleAccount";
import {SetCustomerMetrics} from "../../modules/campaign/redux/customerMetrics";
import {SetCampaigns} from "../../modules/campaign/redux/campaigns";
import {useNavigate} from "react-router";

const GoogleAccountCallback = () => {
    const location = useLocation();
    const baseUrl = process.env.REACT_APP_ADMIN_API;
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const googleAccountValues = useSelector(googleAccountValue)
    // const navigate = useNavigate();
    //
    // const handleGoogleCallback = useCallback(async (authCode) => {
    //     localStorage.setItem('is_trying_to_connect_with_google', 1);
    //
    //     try {
    //         const response = await axios.post(baseUrl + '/v1/google-account/connect-to-account', { auth_code: authCode }, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${user.token}`,
    //             },
    //         });
    //
    //         const token = response.data;
    //         await localStorage.setItem('google_account', JSON.stringify(response.data.google_account));
    //
    //         dispatch(SetGoogleAccount(response.data));
    //
    //         // Ensure this line executes after the localStorage.setItem
    //         navigate('/choose-customer');
    //     } catch (error) {
    //         console.error(error);
    //         localStorage.setItem('is_trying_to_connect_with_google', 3);
    //     }
    // }, [baseUrl]);

    // useEffect(() => {
    //     const searchParams = new URLSearchParams(location.search);
    //     const authCode = searchParams.get('code');
    //
    //     if (authCode) {
    //         handleGoogleCallback(authCode);
    //     }
    // }, [location.search, handleGoogleCallback]);

    // Render a loading message or redirect to another page after processing the callback

        return <Navigate to="/" />;


};

export default GoogleAccountCallback;