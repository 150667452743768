import React from 'react';

const ArrowChart = ({ color ,className}) => (
<svg width="80" height="80" viewBox="0 0 80 80" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="40" cy="39.9007" rx="40" ry="39.9007" fill="#54D5B6" fillOpacity="0.15"/>
    <path d="M53.5219 33.1235L43.5914 39.8437L53.6967 54.7024L50.3866 56.9425L40.2813 42.0837L30.3508 48.8038L29.5854 22.8029L53.5219 33.1235Z" fill="black"/>
</svg>

);

export default ArrowChart;