import {TabPanel, TabView} from "primereact/tabview";
import React from "react";
import {Home} from "./home";
import {SystemHome} from "./system_home";

function SystemWithGoogleHome() {

    return (
        <div>
            <div className="container-fluid home-container">
                <div className="flex flex-wrap gap-2 justify-content-between align-items-center filter-div">
                    <div className="tabview-div home-tabview">
                        <TabView>
                            <TabPanel header="Google" className={'google-tabview'}>
                                <Home/>
                            </TabPanel>
                            <TabPanel header="System" className={'system-tabview'}>
                                <SystemHome/>
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>


        </div>
    );
}

export default SystemWithGoogleHome;