import React from 'react';

const MetricsImpressions = ({color, className}) => (

    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0.420471V24.1275C0 24.4974 0.146431 24.8521 0.407079 25.1136C0.667728 25.3751 1.02124 25.522 1.38986 25.522H25.0174V22.733H2.77971V0.420471H0Z"
            fill="black"/>
        <path
            d="M17.0856 16.7461C17.2146 16.8757 17.3678 16.9785 17.5364 17.0487C17.705 17.1189 17.8857 17.155 18.0683 17.155C18.2508 17.155 18.4316 17.1189 18.6002 17.0487C18.7688 16.9785 18.9219 16.8757 19.0509 16.7461L26.0002 9.77344L24.0349 7.80157L18.0683 13.7883L14.8813 10.5906C14.7524 10.461 14.5992 10.3582 14.4306 10.288C14.262 10.2179 14.0812 10.1818 13.8987 10.1818C13.7162 10.1818 13.5354 10.2179 13.3668 10.288C13.1982 10.3582 13.045 10.461 12.9161 10.5906L5.9668 17.5633L7.93205 19.5352L13.8987 13.5484L17.0856 16.7461Z"
            fill="black"/>
    </svg>


);

export default MetricsImpressions;