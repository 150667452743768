import { createSlice } from '@reduxjs/toolkit'

export const campaignRecommendationsSlice = createSlice({
    name: 'campaignRecommendations',
    initialState: {
        value: [],
    },
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = 'metrics'
        },
        decrement: (state) => {
            state.value = ''
        },
        SetCampaignRecommendations: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { increment, decrement, SetCampaignRecommendations } = campaignRecommendationsSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(SetCampaignRecommendations(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.newCampName.value)`
export const recommendationsValues = (state) => state.recommendations.value

export const allCampaignRecommendationsValues = function (state) {
    let campaignRecommendations
    campaignRecommendations = [];
    if (state.campaignRecommendations.value !== []){
        campaignRecommendations = state.campaignRecommendations.value



        return campaignRecommendations
    }else{
        return -1
    }

}

export default campaignRecommendationsSlice.reducer
