import React from 'react';

const MetricsRate = ({color, className}) => (

    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.725 7.85C10.725 9.08667 10.3167 10.1017 9.5 10.895C8.68333 11.665 7.575 12.05 6.175 12.05H4.95C3.55 12.05 2.44167 11.665 1.625 10.895C0.808333 10.1017 0.4 9.08667 0.4 7.85V4.35C0.4 3.11333 0.808333 2.11 1.625 1.34C2.44167 0.546665 3.55 0.149999 4.95 0.149999H6.175C7.575 0.149999 8.68333 0.546665 9.5 1.34C10.3167 2.11 10.725 3.11333 10.725 4.35V7.85ZM2.5 4.35V7.85C2.5 8.55 2.71 9.11 3.13 9.53C3.55 9.92667 4.15667 10.125 4.95 10.125H6.175C6.96833 10.125 7.575 9.92667 7.995 9.53C8.415 9.11 8.625 8.55 8.625 7.85V4.35C8.625 3.65 8.415 3.10167 7.995 2.705C7.575 2.285 6.96833 2.075 6.175 2.075H4.95C4.15667 2.075 3.55 2.285 3.13 2.705C2.71 3.10167 2.5 3.65 2.5 4.35ZM18.775 0.499998H20.7V1.55L6.175 25H4.25V23.95L18.775 0.499998ZM24.55 21.15C24.55 22.3867 24.1417 23.4017 23.325 24.195C22.5083 24.965 21.4 25.35 20 25.35H18.775C17.375 25.35 16.2667 24.965 15.45 24.195C14.6333 23.4017 14.225 22.3867 14.225 21.15V17.65C14.225 16.4133 14.6333 15.41 15.45 14.64C16.2667 13.8467 17.375 13.45 18.775 13.45H20C21.4 13.45 22.5083 13.8467 23.325 14.64C24.1417 15.41 24.55 16.4133 24.55 17.65V21.15ZM16.325 17.65V21.15C16.325 21.85 16.535 22.41 16.955 22.83C17.375 23.2267 17.9817 23.425 18.775 23.425H20C20.7933 23.425 21.4 23.2267 21.82 22.83C22.24 22.41 22.45 21.85 22.45 21.15V17.65C22.45 16.95 22.24 16.4017 21.82 16.005C21.4 15.585 20.7933 15.375 20 15.375H18.775C17.9817 15.375 17.375 15.585 16.955 16.005C16.535 16.4017 16.325 16.95 16.325 17.65Z"
            fill="#15D5FF"/>
    </svg>


);

export default MetricsRate;