import React from 'react';

const MetricsClick = ({color, className}) => (

    <svg width="25" height="35" viewBox="0 0 25 35" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.5219 11.1607L14.5914 17.8809L24.6967 32.7396L21.3866 34.9797L11.2813 20.1209L1.35077 26.841L0.585445 0.84015L24.5219 11.1607Z"
            fill="#15D5FF"/>
    </svg>


);

export default MetricsClick;