import React from 'react';

const OptimizationScoreIcon = ({className,percentage}) => (

    <svg width="500" height="50" viewBox="0 0 500 50" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <rect y="34" width="50" height="500" rx="8.5" transform="rotate(-90 0 50)" fill="#D1D1D1" fillOpacity="0.3"/>
        <rect y="34" width="50" height={percentage} rx="8.5" transform="rotate(-90 0 50)" fill="#29D683"/>
    </svg>


);

export default OptimizationScoreIcon;