import React from 'react';

const CampaignIcon = ({color, className}) => (
    <svg width="50" height="39" viewBox="0 0 50 39" fill={color} className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41.1782 20.5878V17.8566H50V20.5878H41.1782ZM44.1416 38.2367L37.0814 32.941L38.783 30.7778L45.8431 36.0709L44.1416 38.2367ZM38.5727 7.45616L36.8711 5.29306L43.9313 0L45.6328 2.16311L38.5727 7.45616ZM7.24859 35.5055V25.1052H4.41361C3.19004 25.1052 2.14854 24.6754 1.28912 23.816C0.429709 22.9566 0 21.9151 0 20.6915V17.7528C0 16.5292 0.429709 15.4877 1.28912 14.6283C2.14854 13.7689 3.19004 13.3392 4.41361 13.3392H14.601L25.422 6.93177V31.5125L14.601 25.1052H9.97979V35.5055H7.24859ZM22.6908 26.6483V11.796L15.3712 16.0704H4.41088C3.99028 16.0704 3.60427 16.2452 3.25286 16.5947C2.90326 16.9462 2.72846 17.3322 2.72846 17.7528V20.6915C2.72846 21.1121 2.90326 21.4981 3.25286 21.8496C3.60427 22.1992 3.99028 22.374 4.41088 22.374H15.3657L22.6853 26.6483H22.6908ZM30.2562 27.1126V11.3317C31.1684 12.1784 31.904 13.2909 32.463 14.6693C33.0202 16.0458 33.2987 17.5625 33.2987 19.2194C33.2987 20.8763 33.0202 22.3931 32.463 23.7696C31.904 25.1479 31.1675 26.2604 30.2535 27.1071L30.2562 27.1126Z"
            />
    </svg>
);

export default CampaignIcon;