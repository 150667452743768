import React from 'react';

const MetricsConversation = ({color, className}) => (

    <svg width="25" height="32" viewBox="0 0 25 32" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.7314 23.4373L24.3666 24.0725L25 23.4373L24.3666 22.8021L23.7314 23.4373ZM23.0962 22.8021L15.919 29.9793L17.1894 31.2497L24.3666 24.0725L23.0962 22.8021ZM24.3666 22.8021L17.1894 15.6249L15.919 16.8952L23.0962 24.0725L24.3666 22.8021ZM23.7314 22.5401H1.30256V24.3345H23.7314V22.5401Z"
            fill="#FFA41B"/>
        <path
            d="M1.26858 7.81246L0.633391 7.17727L0 7.81246L0.633391 8.44764L1.26858 7.81246ZM1.90376 8.44764L9.081 1.27041L7.81063 3.71933e-05L0.633391 7.17727L1.90376 8.44764ZM0.633391 8.44764L7.81063 15.6249L9.081 14.3545L1.90376 7.17727L0.633391 8.44764ZM1.26858 8.70961L23.6974 8.70961V6.9153L1.26858 6.9153V8.70961Z"
            fill="#FFA41B"/>
    </svg>


);

export default MetricsConversation;