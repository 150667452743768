import React from 'react';

const LockIcon = ({color, className}) => (
    <svg width="102" height="120" viewBox="0 0 102 120" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M51.015 63.5338C48.8655 63.5422 46.7782 64.2563 45.074 65.5664C43.3698 66.8766 42.1431 68.7101 41.5824 70.7853C41.0217 72.8605 41.1581 75.0624 41.9707 77.0525C42.7832 79.0426 44.2269 80.7107 46.0797 81.8004V95.7938H56.0707V81.8004C57.5367 80.9333 58.7533 79.7016 59.6023 78.2251C60.4513 76.7486 60.9038 75.0775 60.9157 73.3743C60.9158 72.077 60.6592 70.7924 60.161 69.5946C59.6627 68.3967 58.9324 67.3092 58.0123 66.3947C57.0921 65.4801 56.0002 64.7565 54.7993 64.2655C53.5985 63.7746 52.3124 63.5259 51.015 63.5338Z"
            stroke="#0511F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M87.9997 40.9339H14.0003C6.82043 40.9339 1 46.7543 1 53.9342V105.364C1 112.544 6.82043 118.364 14.0003 118.364H87.9997C95.1796 118.364 101 112.544 101 105.364V53.9342C101 46.7543 95.1796 40.9339 87.9997 40.9339Z"
            stroke="#0511F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M18.9657 40.9338V33.0794C18.9657 24.5714 22.3455 16.4119 28.3616 10.3959C34.3776 4.37979 42.5371 1 51.0451 1C59.5531 1 67.7127 4.37979 73.7287 10.3959C79.7448 16.4119 83.1246 24.5714 83.1246 33.0794V40.9338"
            stroke="#0511F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


);

export default LockIcon;