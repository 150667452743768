import React from 'react';

const MetricsBag = ({color, className}) => (
    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.24911 32.4096C4.24718 34.8237 7.96359 34.8237 15.3997 34.8237H16.5986C24.0347 34.8237 27.7528 34.8237 29.7509 32.4096M2.24911 32.4096C0.251043 29.9938 0.937046 26.3284 2.30739 18.9942C3.28145 13.7817 3.76765 11.1738 5.61753 9.63349M29.7509 32.4096C31.749 29.9938 31.063 26.3284 29.6926 18.9942C28.7186 13.7817 28.2307 11.1738 26.3808 9.63349M26.3808 9.63349C24.5326 8.09314 21.8868 8.09314 16.6003 8.09314H15.3981C10.1115 8.09314 7.46741 8.09314 5.61753 9.63349"
            stroke="#FFA41B" strokeWidth="2"/>
        <path d="M12.668 21.9596L14.8875 23.9644L19.3282 18.9524" stroke="#FFA41B" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M11.0029 8.09304V6.42238C11.0029 5.09312 11.5292 3.8183 12.466 2.87838C13.4028 1.93845 14.6733 1.4104 15.9981 1.4104C17.3229 1.4104 18.5935 1.93845 19.5302 2.87838C20.467 3.8183 20.9933 5.09312 20.9933 6.42238V8.09304"
            stroke="#FFA41B" strokeWidth="2" strokeLinecap="round"/>
    </svg>


);

export default MetricsBag;