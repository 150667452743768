import React from 'react';

const MobileIcon = ({ className}) => (

<svg width="22" height="35" viewBox="0 0 22 35" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5556 0H2.44444C1.79614 0 1.17438 0.257415 0.715962 0.715616C0.257539 1.17382 0 1.79527 0 2.44327V31.7625C0 32.4105 0.257539 33.0319 0.715962 33.4901C1.17438 33.9483 1.79614 34.2057 2.44444 34.2057H19.5556C20.2039 34.2057 20.8256 33.9483 21.284 33.4901C21.7425 33.0319 22 32.4105 22 31.7625V2.44327C22 1.79527 21.7425 1.17382 21.284 0.715616C20.8256 0.257415 20.2039 0 19.5556 0ZM2.44444 2.44327H19.5556V24.4327H2.44444V2.44327ZM2.44444 31.7625V26.8759H19.5556V31.7625H2.44444Z" fill="black" fillOpacity="0.5"/>
    <path d="M9.77832 28.0976H12.2228V30.5408H9.77832V28.0976Z" fill="black" fillOpacity="0.5"/>
</svg>


);

export default MobileIcon;